#page-container {
  width: 96%;
  max-width: 1920px;
  margin: 25px auto;
  padding: 4px 6px 50px 6px;
  background-color: rgba(255,255,255, 1);
  /* background-color: rgba(255,255,255, 0.75); */
  border: 3px solid rgba(255,255,255, 1);
  /* box-shadow: 0 0 10px 1px rgba(0,0,0, 0.2); */
  border-radius: 8px;
  min-height: 100%;
}