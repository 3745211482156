.custom-select-container {
    position: relative;
}

.custom-select-value {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    min-height: 40px;
    align-items: center;
    display: flex;
    position: relative;
    cursor: pointer;
    padding: 3px;
}

.custom-select-value:focus::after {
    transform: rotate(90deg);
    border-bottom: 1px solid var(--third-color);
    ;
}

.custom-select-value:after {
    content: '>';
    font-weight: 600;
    line-height: 0px;
    font-size: 20px;
    transition: all 0.2s;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    transform-origin: center;
    right: 5px;
}

.custom-select-options {
    position: absolute;
    opacity: 0;
    transition: all 0.2s;
    z-index: -1;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.custom-select-option {
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.2s;
    cursor: pointer;
}

.custom-select-option.selected,
.custom-select-option:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.custom-select-option:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.custom-select-value:focus+.custom-select-options {
    opacity: 1 !important;
    z-index: 9999999;
}