#comments-section {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    align-items: flex-start;
}


#comments-section>div {
    padding-top: 5px;
}

#comments-section>div:first-child {
    margin-bottom: 0px;
    border-bottom: none;
    padding-bottom: 0px;
}

.comment-line {
    border-bottom: 1px solid rgba(225, 225, 225);
    width: 100%;
    padding-bottom: 10px;
    justify-content: flex-start;
    text-align: left;
}

.date-comment {
    min-width: 155px;
    font-weight: 300;
}

.text-comment {
    font-size: 15px;
}

.text-comment p {
    text-align: left;
    font-size: 14px;
}

.rdw-editor-wrapper {
    height: calc(100% - 10px);
    min-height: 70px;
}