/* PRIORITY INPUT */
.priority-input-container {
    background-color: #ff00006e;
    padding: 6px;
    border-radius: 5px;
  }
  
  .priority-label {
    color: white;
  }
  
  .priority-input {
    background-color: white;
    color: #ff0000e1;
    border: none !important;
  }
  
  .disabled {
    opacity: 0.3;
  }