.popup {
    width: calc(100% - 110px);
    margin: 60px auto;
    left: 0;
    right: 0;
    position: relative;
    top: 0;
    z-index: 100001;
    background-color: white;
    padding: 7px 15px;
    border-radius: 10px;
    opacity: 1;
    /* overflow: hidden; */
    transition: opacity 0.7s linear, max-width 0.2s linear;
    resize: horizontal;
}

.full-width-popup {
    border-radius: 0px !important;
    margin: 0 !important;
    width: calc(100% - 30px) !important;
    max-width: unset !important;
}

.popup h2,
.popup2Content h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(75, 75, 75);
    text-align: center;
    font-weight: 700;
}

.popup2 {
    z-index: 100002;
    box-shadow: 0 0 50px 50px rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(1px);
    transition: opacity 0.2s linear;
}

.popup2Content {
    background-color: white;
    max-width: 850px;
    max-width: 850px;
    width: 95%;
    margin: auto;
    left: 0;
    right: 0;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 10px;
    padding: 7px 15px;
    max-height: 95%;
    overflow-y: auto;
}