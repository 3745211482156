.third-navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.third-navbar-button {
    /* border-bottom: 1px solid rgba(60, 60, 60); */
    width: 100%;
    text-align: center;
    text-decoration: none;
    padding: 10px 3px;
    transition: all 0.5s;
    color: rgba(60, 60, 60);
    font-size: 18px;
    border-radius: 2px;
    font-weight: 600;
    opacity: 0.6;
    background-color: rgba(255, 255, 255, 0);
    box-shadow:
        4px 7px 6px 0 rgb(116 125 136 / 20%), inset 0px -4px 4px 0 rgb(116 125 136 / 30%);
}

.third-navbar-button:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 5px;
}

.third-navbar-button:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
}

.third-navbar-button.active,
.third-navbar-button.first-active {
    box-shadow:
        -4px -7px 6px 0 rgb(116 125 136 / 20%), inset 4px 4px 4px 0 rgb(116 125 136 / 30%);
    opacity: 1;
}