.carroussel-image {
    min-width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.main-image-post {
    max-width: 435px;
    width: 100%;
}

.main-post-image {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 300px;
    max-width: 435px;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    border-radius: 8px;
}