.public-DraftStyleDefault-block {
    margin: 0 !important;
}

.rdw-editor-toolbar {
    padding: 0 !important;
}

.rdw-option-wrapper {
    margin: 0px !important;
}

.rdw-editor-toolbar>div {
    margin: 0 !important;
}

.rdw-editor-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    background-color: white;;
}