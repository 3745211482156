/* #create-organisation{
    max-width: 500px;
    margin: auto;
} */

/* #invoice-main-container .input {
    min-width: 410px;
} */

#invoice-main-container textarea {
    min-width: 200px;;
}

#invoice-main-container .pipedrive-info {
    text-align: left;
    font-size: 12px;
    font-weight: 300;
}