.main-nav-bar{
    padding: 15px 10px;
    background-color: white;
    box-shadow: 0 0 10px 1px rgba(60,60,60,0.3); 
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-nav-button{
    text-decoration: none;
    color: rgba(60,60,60,1);
    font-size: 24px;
    font-weight: 700;
    transform: all 0.2s;
    text-shadow: 0 0 10px rgba(60,60,60,0);
    border-bottom: 1px solid rgba(60,60,60,0);
    text-transform: uppercase;
}

.main-nav-button:not(:last-child) {
    margin-right: 20px;
}

.main-nav-button.active {
    border-bottom: 1px solid rgba(60,60,60,1);
}