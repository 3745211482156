.variation {
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 5px;
}

.variation-parameters-container {
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 3px;
    border-radius: 10px;
    margin: 5px 0;
}

.add-variation-container {
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    padding: 7px;
}

.variation-value {
    background-color: var(--fith-color);
    border-radius: 8px;
    padding: 15px 7px 0px 7px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.variation-value div:not(.column)>input,
.option-value select {
    max-width: 130px !important;
}

.variation-value div.column>input, .variation-value div.column>select {
    width: 100%;
    box-sizing: border-box;
}