.app-button{
    width: 260px;
    height: 150px;
    display: block;
    border: 1px solid rgba(60, 60, 60, 1);
    margin: 10px;
    border-radius: 5px;  
    transition: all 0.2s;  
    transform: scale(1);
    text-decoration: none;
    font-size: 30px;
    text-align: center;
    color: rgba(60, 60, 60, 1);
    display: table-cell;
    vertical-align: middle;
    font-weight: 600;
}

.app-button:hover{
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
    transform: scale(1.03);
}

.app-container{
    display: flex;
    justify-content: space-around;
    margin: 25px auto;
    flex-wrap: wrap;
    gap: 12px;
}

.app-container > div {
    text-transform: uppercase;
}