.product-fields input[type='number'] {
    max-width: 200px;
}

.tox-menubar {
    display: none !important;
}

.carrousel-picture {
    position: relative;
}

.carrousel-picture img {
    width: 200px;
    aspect-ratio: 1/1;
    object-fit: contain;
    border-radius: 8px;
    border: 1px solid var(--third-color);
}

.carrousel-picture .delete-picture {
    font-size: 50px;
    position: absolute;
    right: 0;
    top: -12px;
    cursor: pointer;
}