.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(3px);
    overflow-y: auto;
    transition: all 0.2s;
}

.close-overlay {
    position: fixed;
    top: 3px;
    right: 9px;
    z-index: 10001
}

.close-overlay .leftright,
.close-overlay .rightleft {
    background-color: white;
}