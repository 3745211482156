
/*  LOADER  */

.loader {
    position: absolute;
    z-index: 9999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 200px;
    height: 200px;
    opacity: 1;
    transition: all 0.2s;
}

.loader svg {
    width: 90%;
    fill: none;
}

.load {
    transform-origin: 50% 50%;
    stroke-dasharray: 570;
    stroke-width: 20px;
}

.load.one {
    stroke: #554d73;
    animation: load 1.5s infinite;
}

.load.two {
    stroke: #a496a4;
    animation: load 1.5s infinite;
    animation-delay: 0.1s;
}

.load.three {
    stroke: #a5a7bb;
    animation: load 1.5s infinite;
    animation-delay: 0.2s;
}

.point {
    animation: bounce 1s infinite ease-in-out;
}

.point.one {
    fill: #a5a7bb;
    animation-delay: 0s;
}

.point.two {
    fill: #a496a4;
    animation-delay: 0.1s;
}

.point.three {
    fill: #554d73;
    animation-delay: 0.2s;
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

@keyframes load {
    0% {
        stroke-dashoffset: 570;
    }
    50% {
        stroke-dashoffset: 530;
    }
    100% {
        stroke-dashoffset: 570;
        transform: rotate(360deg);
    }
}