.notification {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 1000000;
    width: 350px;
    background-color: rgba(51, 51, 51);
    border-radius: 5px;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
    padding: 10px;
    transition: all 0.2s;
}

.notification.error {
    background-color: rgb(214, 101, 101);
}

.notification-header {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255);
}

.notification-title {
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    color: white;
    margin-bottom: 0px;
    padding-bottom: 2px;
}

.notification-content {
    padding-top: 5px;
    font-size: 18px;
    text-align: left;
    /* color: rgb(30, 30, 30) */
    color: white;
}

#close-notification {
    border-radius: 50%;
    padding: 2px 0px 0;
    cursor: pointer;
    height: 22px;
    width: 24px;
    font-size: 24px;
    position: absolute;
    right: 0px;
    top: -9px
}


.notification-content .lds-dual-ring:after {
    border: 6px solid rgb(255, 255, 255) !important;
    border-color: rgb(255, 255, 255) transparent rgb(255, 255, 255) transparent !important;
}