.accordion-table .show-sublines {
    font-size: 20px !important;
    color: rgba(60, 60, 60);
    padding: 15px;
    transform: rotate(0deg);
    transition: all 0.4s;
}

.accordion-table .active .show-sublines {
    transform: rotate(90deg);
}

.accordion-table .main-line {
    cursor: pointer;
    background-color: rgba(60, 60, 60, 0.001);
}

/* .accordion-table .main-line.active {
    background-color: rgba(60, 60, 60, 0.05) !important;
} */

.accordion-table tr td {
    height: unset !important;
}

.accordion-table .subRaw {
    background-color: white !important;
    border: none !important;
}

.accordion-table .subRaw:hover {
    box-shadow: none;
}

.accordion-table .subRaw:first-child {
    box-shadow: inset 0 0 20px 2px rgba(60, 60, 60, 1) !important;
}

.accordion-table .subRaw.active {
    border-bottom: 1px solid rgba(60, 60, 60, 0.2) !important;
}

.accordion-table .subRaw>td>div {
    max-height: 0;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;
    transition: max-height 0.3s, padding 0.3s;
}

.accordion-table .subRaw.active td>div {
    max-height: 100px;
    padding: 10px 10px;
    transition: max-height 0.6s, padding 0.6s;
}

.accordion-table .subRaw>td {
    padding: 0 !important;
}