.recipe-step {
    /* box-shadow: 0 2px 12px rgba(0,40,117,.06); */
    margin: 6px 0 15px 0;
    padding: 5px 12px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.6);
    /* width: 100%; */
    background-color: var(--fith-color);
}

.recipe-step textarea {
    width: 100%;
}

.batch-ingredient {
    background-color: var(--fith-color);
}

.ingredient-fields {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    background-color: var(--fith-color);
    padding: 5px 7px 10px 7px;
    border-radius: 8px;
}