.erp-main-navigation {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    padding: 10px 10px;
}

.erp-navigation-button {
    margin-right: 20px;
    width: 250px;
    background-color: var(--fith-color);
    /* background-color: var(--primary-color); */
    color: var(--primary-color);
    ;
    border: none;
    padding: 15px 10px;
    /* min-height: 60px; */
    opacity: 0.8;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    text-align: center;
    display: table-cell;
    vertical-align: middle;

}

.erp-navigation-button.active {
    font-weight: 600;
    opacity: 1;
    /* border: 1px solid rgba(60,60,60); */
}

.my-account-button {
    text-decoration: none !important;
}