.show-on-hover {
    opacity: 0;
    transition: opacity 0.3s;
}

tr:hover .show-on-hover {
    opacity: 1;
}

.filter-container {
    margin-right: 25px;
    position: relative;
    display: flex;
    align-items: center;
}