#dd-table-section {
    min-width: 450px;
    width: 100%;
    margin: 0 auto 20px auto;
}

#main-section-container {
    flex-wrap: wrap;
}

#dd-org-section {
    margin-bottom: 20px;
    margin: 0 auto 20px auto;
    font-size: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.info-section {
    margin-top: 7px;
    margin-bottom: 3px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgb(100, 100, 100, 0.2);
}

.info-section .strong-2 {
    text-align: left;
}

#custom-deal-parameters {
    width: 100%;
    border-top: 1px solid rgb(100, 100, 100);
    border-bottom: 1px solid rgb(100, 100, 100);
    padding: 10px 0;
    margin: 20px auto;
}

#custom-deal-parameters label {
    font-weight: 600;
    font-size: 16px;
}

#custom-deal-parameters input,
#custom-deal-parameters select {
    width: 200px;
    height: 40px;
    margin-left: 10px;
}

#custom-deal-parameters textarea {
    width: 100%;
}

.column-flex {
    display: flex;
    flex-direction: column;
}

.product-ignored {
    opacity: 0.6;
}

.not-ok-quantity {
    color: rgb(180, 18, 18) !important;
    font-weight: 600;
}

.ok-quantity {
    color: rgb(19, 148, 19) !important;
    font-weight: 600;
}

.comment-deal-detail,
.comment-deal-detail p {
    font-size: 14px !important;
    color: rgb(80, 80, 80) !important;
    text-align: left !important;
}