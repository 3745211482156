.option {
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 5px;
}

.form-option-container {
    border-radius: 8px;
    padding: 7px;
    border: 1px solid var(--primary-color);
}

.option-value {
    background-color: var(--fith-color);
    border-radius: 8px;
    padding: 15px 7px 7px 7px;
    width: 45%;
    position: relative;
}

.option-value input, .option-value select {
    max-width: 130px !important;
}

.delete-value {
    font-size: 50px;
    position: absolute;
    right: -10px;
    top: -20px;
    cursor: pointer;
}