.group {
    position: relative;
    margin: 5px;
    border-radius: 0px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 25px;
}


.group input.input,
.group select.input {
    font-size: 16px;
    padding: 5px 10px 5px 5px;
    display: block;
    border: none;
    border-bottom: 1px solid var(--third-color);
    background: transparent;
    border-radius: 0px;
    margin-bottom: 0;
    width: 100%;
    box-sizing: border-box;
}

.group input.input {
    height: 40px;
}

.group select.input {
    height: 51px;
}

.group .input:focus,
.input:valid~label {
    outline: none;
}

.group.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.group label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

input[list]::-webkit-calendar-picker-indicator {
    opacity: 0;
}

.cancel-select {
    cursor: pointer;
    position: absolute;
    right: 5px;
    font-weight: 700;
    background-color: transparent;
    z-index: 2;
    color: rgb(60, 60, 60);
    font-size: 20px;
    /* text-shadow: 0 0 2px rgb(175 10 10); */
    display: block
}

.required-star {
    color: rgb(160, 0, 0)
}

.group input.input:focus~label,
.group input.valid~label,
.group select.input:focus~label,
.group select.valid~label {
    bottom: calc(90%);
    font-size: 14px;
    color: var(--primary-color);
}

.group .bar {
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0px;
}

.group .bar:before,
.group .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: var(--primary-color);
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.group .bar:before {
    left: 50%;
}

.group .bar:after {
    right: 50%;
}

.input:focus~.bar:before,
.input:focus~.bar:after {
    width: 50%;
}

.highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}

.input:focus~.highlight {
    animation: inputHighlighter 0.3s ease;
}

@keyframes inputHighlighter {
    from {
        background: #var(--primary-color);;
    }

    to {
        width: 0;
        background: transparent;
    }
}