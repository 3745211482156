.close-container {
    position: relative;
    width: 25px;
    height: 25px;
    cursor: pointer;
    top: -2px;
    left: -13px;
    transition: opacity 0.3s;
    border: none;
    background-color: rgba(0, 0, 0, 0) !important;
    opacity: 0;
}

.close-container.always-display {
    opacity: 1 !important;
}

.close-container:hover {
    box-shadow: none !important;
}

tbody tr:hover .close-container {
    opacity: 1;
}

.leftright {
    height: 4px;
    width: 25px;
    position: absolute;
    background-color: #f4a259;
    border-radius: 2px;
    transform: rotate(45deg);
    transition: all 0.2s ease-in;
}

.rightleft {
    height: 4px;
    width: 25px;
    position: absolute;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: all 0.2s ease-in;
}

.close {
    position: absolute;
}

td:hover .close-container .leftright {
    transform: rotate(-45deg);
}

td:hover .close-container .rightleft {
    transform: rotate(45deg);
}

td:hover .close-container label {
    opacity: 1;
}