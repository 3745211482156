#delivery-sheet {
    padding: 30px 50px;
    background: white;
    margin: 10px auto;
}
#delivery-sheet img{
    width: 75px;
    margin-bottom: 20px;
}
.organisation-name {
    font-weight: 700;
}

#delivery-sheet h3{
    text-align: center;
    margin-bottom: 10px;
}

#delivery-infos{
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 25px;
    color: rgb(10,10,10);
    align-items: end;
}

#llfc-infos{
    text-align: left;
}
#organisation-infos{
    text-align: right;
}

#delivery-sheet .beautiful-array th, #delivery-sheet .beautiful-array td {
    /* font-size: 24px; */
    /* line-height: 26px; */
}

#delivery-total-products, #date{
    padding-top: 40px;
    font-size: 18px;
    text-align: left;
    color: rgb(10,10,10);
}

#cgv{
    margin-top: 100px;
    font-size: 11px;
    text-align: left;
}