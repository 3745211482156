.section-arrow {
    transform: scale(2) rotate(90deg);
    margin: 15px auto;
    width: 50px;
    text-align: center;
}

.show-details {
    font-size: 16px;
    font-weight: 500;
    position:absolute;
    margin: auto;
    text-decoration: underline;
}
.session-section {
    /* width: 48%; */
    max-height: 68px;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.session-section-shown {
    max-height: 500px;
}

.session-header {
    padding: 2px 12px;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
}

.session-title {
    text-align: center;
    margin: auto;
}

.main-session-title {
    font-size: 21px;
    font-weight: 600;
}

.secondary-session-title {
    font-size: 16px;
    font-weight: 400;
}

.session-section .step-remove,
.session-section .step-edit {
    transform: scale(1.3);
}

.session-section .step-remove:hover,
.session-section .step-edit:hover {
    transform: scale(1.3);
}

.session-section .beautiful-array th {
    background-color: white;
    height: 40px;
    ;
}

.session-section .beautiful-array tr {
    border: 0px solid;
    height: 30px;
}