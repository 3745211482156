.step-header {
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.step-infos {
    max-height: 350px;
    overflow: hidden;
    height: 0;
    margin-top: 15px;
}

.step-infos-shown {
    max-height: 500px;
}

.step-title {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}

.step-remove,
.step-edit {
    transform: scale(1.5);
    padding: 0;
    border: 1px solid rgb(210, 210, 210);
    border-radius: 5px;
    background-color: white;
}

.step-remove:hover,
.step-edit:hover {
    transform: scale(1.5);
}

.step-section .beautiful-array th {
    background-color: white;
}

.step-section .beautiful-array tr {
    border: 0px solid;
}

.step-section {
    /* width: 48%; */
    max-height: 57px;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.step-infos-shown {
    max-height: 500px !important;
}