.list-item-ingredient:hover .make-it-editable {
    opacity: 1;
}

.make-it-editable {
    position: absolute;
    cursor: pointer;
    text-decoration: underline;
    right: 2px;
    bottom: 0px;
    color: 'rgba(0,0,0,0.9)';
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background-color: white;
    border-radius: 6px;
}

.price-matrix-option {
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    padding: 5px;
}