html {
  min-height: 100%;
}

:root {
  --primary-color: #3D553D;
  --secondary-color: #FFF;
  --third-color: #646464;
  --forth-color: #000;
  --fith-color: #EEF9EF;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
  margin: 0;
  font-family: "Nexa", "Open Sans", monospace, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-color: var(--fith-color);
  min-height: 100%;
}

.react-datalist-input__listbox {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* POPUP, overlay,... */
.hidden-overlapping-element {
  opacity: 0 !important;
  z-index: -1 !important;
}

h1 {
  margin: 30px 0px 30px 0px;
  text-align: left;
}

h3 {
  color: var(--primary-color);
  background-color: var(--fith-color);
  margin: 0 0 10px 0;
  padding: 10px 3px;
  border-radius: 8px;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}

h4 {
  color: var(--primary-color);
  font-size: 18px;
}


h5 {
  font-size: 22px;
  text-align: center;
}

table {
  border-collapse: collapse;
  border-spacing: 0 5px;
}

td,
th {
  padding: 5px 5px
}

th {
  font-size: 16px;
}

th {
  border-bottom: 1px solid rgb(120, 120, 120);
}

p {
  font-size: 18px;
  text-align: center;
  margin: 5px 0;
}

.subtext {
  font-size: 14px;
  color: rgb(80, 80, 80);
}

.mediumtext {
  font-size: 16px;
}

a {
  color: var(--primary-color)
}

.primary-color {
  color: var(--primary-color)
}

.flex {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-basis {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-v-start {
  display: flex;
  align-items: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-v-end {
  display: flex;
  align-items: flex-end;
}

.evenly {
  justify-content: space-evenly;
}

.flex-1 {
  flex: 1;
}

.self-end {
  align-self: end;
}

.self-center {
  align-self: center;
}

.gap-1 {
  gap: 10px
}

.gap-2 {
  gap: 20px
}

.wrap {
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column !important;
}

.center {
  align-items: center;
}

.start {
  align-items: flex-start;
}

.end {
  align-items: end;
}

.justify-start {
  justify-content: start !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-stretch {
  justify-content: center !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100%;
}

.half-width {
  width: 50%;
}

.half-m-width {
  width: 48%;
}

.soixante-width {
  width: 60%;
}

.quarante-width {
  width: 40%;
}

.regular {
  font-weight: 400 !important;
}

.strong-1 {
  font-weight: 500 !important;
}

.strong-2 {
  font-weight: 600 !important;
}

.underline {
  text-decoration: underline;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.center-block {
  display: block;
  margin: auto;
}

.blur {
  filter: blur(2px);
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}


.m-0 {
  margin: 0 !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-1 {
  margin-bottom: 1px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-w-1220 {
  max-width: 1220px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

/* INPUT */
.title-text-input {
  border: none !important;
  font-size: 26px !important;
  width: 100% !important;
  text-align: center;
  cursor: pointer;
}

.full-width-input:focus-visible {
  border: 0px solid rgba(0, 0, 0, 0) !important;
  border-bottom: 2px solid var(--primary-color) !important;
}

.full-width-input {
  width: 100% !important;
  border: unset !important;
  border-bottom: 1px solid var(--primary-color) !important;
  border-radius: 0px !important;
}

.small-input {
  width: 60px !important;
}

.small-input-2 {
  width: 45px !important;
  height: 16px !important;
  padding-top: 5px;
}

.small-select-2 {
  width: 45px !important;
  height: 23px !important;
  padding: 3px;
}

.invisible-input {
  border: none !important;
  appearance: none !important;
}

.interactive-input {
  background: #e7e7e7;
}

/* BOUTONS */
button.light-button,
a.light-button {
  color: var(--primary-color);
  position: relative;
  background: white;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  padding: 8px 12px;
  transition: all 0.1s ease-in-out;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--primary-color);
  transition: all 0.2s;
  text-decoration: none;
  white-space: nowrap;
}

.light-button a {
  text-decoration: none;
}

button:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

/* BOUTONS */
button.light-button:active,
a.light-button:active {
  box-shadow: inset 0px 4px 4px 0 rgb(111 108 222 / 20%), inset 0 -7px 9px -7px rgba(112, 108, 222, 0.295) !important;
}

button.strong-button,
a.strong-button {
  cursor: pointer;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: var(--primary-color);
  /* border: 2px solid #7471df; */
  /* border: none; */
  font-size: 20px;
  /* text-transform: uppercase; */
  color: white;
  margin: auto;
  display: block;
  transition: all 0.2s;

}

button {
  font-size: 16px;
  background-color: white;
  border: 1px solid rgb(120, 120, 120);
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0);
  transition: all 0.2s;
}

form.submitting button:not([type='button']) {
  cursor: wait !important;
  opacity: 0.7;
}

button:not(.light-button):hover {
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

table button {
  margin: 3px 2px;
}

.erp-button {
  margin: auto;
  cursor: pointer;
}

.erp-big-button {
  height: 50px;
  width: 250px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.erp-medium-button {
  height: 40px;
  width: 200;
  font-size: 20px;
  font-weight: 600;
  /* text-transform: uppercase; */
}

button.error-strong-button {
  background-color: rgba(255, 0, 0) !important;
  border: none !important;
  color: white !important;
}

.delete-button,
.error {
  color: rgba(152, 0, 0) !important;
}

button.error-light-button {
  color: rgb(152, 0, 0);
  position: relative;
  background: white;
  border-radius: 32px;
  text-align: center;
  cursor: pointer;
  padding: 10px 15px;
  transition: all 0.1s ease-in-out;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid rgba(152, 0, 0);
  transition: all 0.2s;
}

/* BOUTONS */
.error-light-button:active {
  box-shadow: inset 0px 4px 4px 0 rgb(111 108 222 / 20%), inset 0 -7px 9px -7px rgba(112, 108, 222, 0.295) !important;
}

.error-light-button:hover {
  box-shadow: 0 0 10px 1px #6e6ee03b;
}

.erp-close-button {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 5px;
  padding-left: 6px;
  cursor: pointer;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.3s transform;
  transform: scale(1);
}

#wpwrap {
  background: linear-gradient(white 0% 50%, #a5a7bb 100%);
  min-height: 100%;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  -webkit-appearance: textfield;
}

/* styles des boutons */

.erp-button {
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 10%);
  cursor: pointer;
  border: 1px solid rgb(80, 80, 80);
  cursor: pointer;
}


/*  Style des inputs / flex section  */

form {
  margin: auto;
}

.input {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
}

/* CHECKBOOOOX */
.erp-check {
  position: relative;
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
  border-radius: 2px;
  appearance: none;
  background-color: white;
  transition: all .3s;
  cursor: pointer;
}

.erp-check::before {
  content: '';
  position: absolute;
  border: solid #fff;
  display: block;
  width: .3em;
  height: .6em;
  border-width: 0 .2em .2em 0;
  z-index: 1;
  opacity: 0;
  right: calc(50% - .3em);
  top: calc(50% - .6em);
  transform: rotate(0deg);
  transition: all .3s;
  transform-origin: center center;
}

.erp-check:checked {
  animation: a .3s ease-in forwards;
  background-color: var(--primary-color);
}

.erp-check:checked::before {
  opacity: 1;
  transform: rotate(405deg);
}

@keyframes a {
  0% {
    opacity: 1;
    transform: scale(1) rotateY(0deg);
  }

  50% {
    opacity: 0;
    transform: scale(.8) rotateY(180deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotateY(360deg);
  }
}

input:not([type="checkbox"]):not([type="radio"]),
select {
  /* width: 200px; */
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.6)
}

input,
select,
textarea {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  font-size: 15px;
  /* font-family: "Open Sans", monospace, sans-serif; */
  font-family: "Nexa", "Open Sans", monospace, sans-serif;
}

label {
  font-size: 14px;
  font-weight: 600;
}

label:has(input[type="checkbox"]) {
  cursor: pointer;
}

/* ARRAY */

.beautiful-array {
  background-color: white;
  border-collapse: collapse;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  text-align: center;
}


.array-no-hover tr:hover {
  box-shadow: none !important;
}

.beautiful-array tr {
  border: 1px solid rgba(0, 0, 0, 10%);
}

.border-none {
  border: none !important;
}

.border-top-none {
  border-top: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.beautiful-array tr th {
  height: 50px;
  background-color: var(--fith-color);
  font-size: 16px;
  padding-left: 4px;
}

.beautiful-array tr td {
  height: 40px;
  padding: 5px 2px;
  font-size: 16px;
  /* background-color: #c3c6dd41; */
}

.beautiful-array tr.important-tr td {
  color: rgba(220, 10, 10, 1);
  font-weight: 600;
}

.beautiful-array tr:hover {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 10%);
}

.flex-center-h {
  justify-content: center;
}

.paginate {
  margin: 50px 0;
}

.paginate-item {
  width: 50px;
  height: 30px;
  border-radius: 100px;
  background-color: red;
  font-size: 1rem;
  color: blue;
}

.paginate-item.active {
  background-color: gray;
}

.text-center {
  text-align: center;
}

.pagination button {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination button.active {
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
}

.pagination button:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.container {
  margin-left: 1px !important;
  width: 500px !important;
}

.title {
  margin-bottom: 20px;
  text-transform: capitalize;
}

.notice {
  display: none !important;
}

#product-comments {
  font-size: 15px;
  color: rgb(50, 50, 50);
  text-align: left;
  border-left: 1px solid;
  padding-left: 10px;
  font-style: italic;
}

/************** PROPRIETE POUR DIFFERENCIER LES ELEMENTS ********************/

.depth-effect {
  border-radius: 16px;
  background: #ffffff;
  padding: 16px 10px;
  box-shadow: -4px -7px 6px 0 rgb(116 125 136 / 20%), inset 4px 4px 4px 0 rgb(116 125 136 / 30%)
}

.box-shadow-1 {
  box-shadow: 0 0 20px 1px rgba(0, 40, 117, .05)
}

/* LIST ITEM */
.list-item {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(210,210,210);
  padding: 7px;
  border-radius: 8px;
  font-size: 16px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 65, 0.05);
}

.primary-color-bg {
  background-color: var(--fith-color);
  border-radius: 10px;
  padding: 10px;
}

.list-item-ingredient {
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  padding: 7px;
  border-radius: 8px;
  font-size: 16px;
}

.list-item-bg {
  background-color: var(--fith-color);
  padding: 5px;
  border-radius: 8px;
}

.list-item-2 {
  box-shadow: 0 2px 12px rgba(0, 40, 117, .06);
  list-style-type: none;
  margin: 6px 0;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
}

.red {
  color: rgba(220, 10, 10, 1);
}

.meta-option {
  font-family: 'Nexa';
  font-weight: 600;
  font-size: 16px;
  color: #3D553D;
  background-color: #F5F5F5;
  border: none;
  padding: 8px;
}

.meta-active {
  border: 1px solid var(--primary-color) !important;
}

.meta-option:hover {
  box-shadow: none !important;
}