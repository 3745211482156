.valide-packaging {
    background-color: var(--fith-color);
    padding: 20px 7px 7px;
    border-radius: 10px;
    width: calc(50% - 24px);
    min-width: 480px;
    position: relative;
}

.valide-packaging .delete-packaging {
    position: absolute;
    top: -15px;
    right: -5px;
    font-size: 22px;
    font-weight: 900;
    cursor: pointer;
}