.event-comments {
    text-align: left;
    font-weight: 500;
    margin: 30px 0;
}

.under-title {
    margin-bottom: 14px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}