.product-abstract-container {
    visibility: visible;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.product-thumbnail {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    max-width: 321px;
    min-width: 220px;
    width: 22%;
    padding: 7px;
    justify-content: space-between;
    position: relative;
}

.public-logo {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    width: 35px;
    filter: drop-shadow(0 0 5px white);
}

.product-abstract-container.hide {
    opacity: 0;
    display: none;
}

.product-abstract-categorie {
    margin-top: 10px;
    color: #A4A4A4;
    text-align: center;
}

.product-abstract-title {
    font-weight: 900;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    margin: 5px 0 10px 0;
    color: var(--primary-color)
}

.product-abstract-labels-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    gap: 6px;
}

.product-abstract-label {
    color: #A4A4A4;
    border: 1px solid #A4A4A4;
    padding: 5px 10px;
    font-size: 14px;
}

.product-picture-container {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    /* Définit la hauteur en fonction de la largeur */
}

.product-abstract-container .product-picture {
    max-width: 100%;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    border: 1px solid rgba(100, 100, 100, 0.2);
}

.product-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    gap: 12px;
}

.product-details>div {
    color: #A4A4A4;
    border: 1px solid #A4A4A4;
    padding: 5px 10px;
    display: block;
    width: auto;
    text-align: center;
    font-size: 14px;
}