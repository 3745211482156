.sub-navigation {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    overflow: hidden;
    position: relative;
    overflow: hidden;
    width: 0px;
    min-width: 0px;
    align-self: flex-start;
    transition: all 0.2s ease-in-out;
}

.sub-navigation.open {
    min-width: 200px;
    width: 200px;
}

.sub-navigation .sub-navigation-button {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.sub-navigation:not(.open) .sub-navigation-button {
    pointer-events: none;
}

.sub-navigation.open .sub-navigation-button {
    opacity: 1;
}

.sub-navigation-toggle {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 50%;
    font-size: 22px;
    pointer-events: all;
}

.outlet-content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 15px;
    border-left: 1px solid var(--primary-color);
}

.sub-navigation-button {
    border: none;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--forth-color);
    background-color: rgba(0, 0, 0, 0);
    padding: 12px 0px;
    margin-right: 10px;
    text-decoration: none;
    font-weight: 500;
}

.sub-navigation-button:last-child {
    margin-right: 0px;

}

.sub-navigation-button.active {
    /* background-color: var(--fith-color); */
    /* border-bottom: 1px solid var(--primary-color); */
    /* border-right: 1px solid var(--primary-color); */
    font-weight: 700;
}