#content rect[fill='#eff4ee'],
#content rect[fill='#ead7f9'],
#content rect[fill='#5e5d5d'],
#content rect[fill='#453150'],
#content rect[fill='#3d553d'] {
    rx: 3px;
    ry: 3px;
    stroke-width: 1;
    stroke: rgba(0, 0, 0, 0.6);
}

.organizaions-revenue-section {
    background-color: var(--fith-color);
    padding: 7px;
    border-radius: 8px;
    margin-top: 25px;
}

text {
    font-family: "Nexa" !important;
}