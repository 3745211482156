.w2p-input {
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;
}

.w2p-input-label {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    top: 0.5rem;
    margin: 0 30px 0 10px;
    padding: 0 3px;
    width: fit-content;
}

.border .w2p-input-label {
    background: white;
    margin: 0;
}

.w2p-input input[type=text],
.w2p-input input[type=number],
.w2p-input textarea {
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    background: white;
    border-radius: 5px;
}

.w2p-input:not(.border) input[type=text],
.w2p-input:not(.border) input[type=number],
.w2p-input:not(.border) textarea {
    border: none;
}

.w2p-input.border input[type=text],
.w2p-input.border input[type=number],
.w2p-input.border textarea {
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.w2p-input input[type=text]:focus,
.w2p-input input[type=number]:focus {
    outline: none;
}

.w2p-input input {
    width: calc(100% - 12px);
    padding: 1px 5px;
}

.w2p-input textarea {
    width: calc(100% - 12px);
    padding: 14px 5px 1px;
}

.required-star {
    color: rgb(160, 0, 0)
}