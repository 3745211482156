/* JAUGE MARGE */
.jauge-container {
    height: 15px;
    position: relative;
    border-radius: 6px;
    display: flex;
    background: linear-gradient(to right,
            #e66465 0%,
            #e66465 10%,
            #f2858d 14%,
            #f2a485 17%,
            #fcb97f 20%,
            #fcc67f 26%,
            #ead78a 29%,
            #d2dfb8 34%,
            #bcdba3 40%,
            rgb(179, 222, 146) 45%,
            #98d79d 50%,
            #93d198 55%,
            #7eca84 100%);
}

.jauge-bar {
    top: 0;
    bottom: 0;
}

.jauge-curseur {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0px;
    transform: translateX(-50%);
    width: 5px;
    height: 30px;
    background-color: #000;
}

.motivation-message {
    display: block;
    margin-bottom: 20px
}