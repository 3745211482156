b {
  /* used for event dates/times */
  margin-right: 3px;
}

.calendar-section {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.calendar-section-sidebar {
  margin-top: 25px;
  line-height: 1.5;
  background: var(--fith-color);
  border-right: 1px solid #d3e2e8;
  text-align: left;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
}

.calendar-section-sidebar li {
  margin-bottom: 6px;
  border-bottom: 1px solid rgb(100, 100, 100);
  padding: 5px 0px;
  cursor: pointer;
}

.comming-sessions {
  background-color: white;
  border-radius: 10px;
  width: 100%;
}

.fc-v-event .fc-event-main {
  color: rgb(10, 10, 10) !important;
  font-weight: 500;
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  max-height: 1000px;
}

.fc .fc-timegrid-slot {
  height: 2.4em !important;
}

.parameters-section {
  text-align: left;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
}

.fc-col-header-cell-cushion {
  color: #7b86dd
}

.calendar-section-sidebar-section {
  padding: 15px 15px 0 15px;
}

.calendar-section-main {
  flex-grow: 1;
  padding: 0px 10px;
}

#planning-container {
  margin: 0 auto;
  width: 100%;
}

.fc .fc-scrollgrid-liquid {
  border-radius: 10px;
  overflow: hidden;
}

.calendar-section-main .fc-v-event {
  /* background-color: #7b86dd; */
  background-color: #c3c6dd;
  border: #2d41db;
}

/* SCROLL BAR */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.select-step-deal_id {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 25px;
  align-items: flex-start;
  gap: 10px;
}

.select-step-deal_id button {
  width: 48%;
}

.select-step-deal_id button:active {
  background-color: var(--fith-color);
}

.select-product-step {
  background-color: white;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  padding: 7px;
}

.select-product-step:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.planning-event-container {
  overflow: hidden;
  font-weight: 600;
  position: relative;
}

.planning-event-container:hover .planning-event-tooltip:not(:hover) {
  opacity: 1 !important;
  display: block;
  z-index: 99999;
}

.planning-event-tooltip {
  position: fixed;
  transition-delay: 0.5s;
  transition: opacity 0.2s ease-in-out;
  padding: 12px;
  font-size: 16px;
  max-width: 400px;
  font-weight: 400;
  bottom: 10px;
  left: 10px;
  border-radius: 8px;
  color: black;
  opacity: 0;
  display: none;
  background-color: white;
  z-index: -1;
  box-shadow: 0 0 100px 10px rgba(0, 0, 0, 0.2);
}