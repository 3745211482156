.change-picture-button {
    width: 100px;
    margin: auto;
    padding: 10px;
    color: white;
    background-color: var(--primary-color);
    z-index: 2;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.change-picture-button input {
    display: none;
}


#popup-product-picture {
    width: 90%;
    max-width: 300px;
    margin: 0px auto 20px auto;
    min-width: 250px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px
}

.valide-packaging-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}

.packaging-section {
    padding-top: 25px;
}

.product-infos {
    padding: 20px;
}

.variation-selection {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.variation-choise {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 10px;
    min-width: 50px;
    font-weight: 400;
    line-height: 2.3;
    cursor: pointer;
    display: block;
    /* opacity: 0.65; */
    font-size: 18px;
    white-space: nowrap;
    transform: all 0.2s;
    padding: 0 10px;
}

.variation-choise.active {
    /* font-weight: 600; */
    color: white;
    background-color: #3D553D;
    opacity: 1;
    box-shadow: 0 0 0px 1px var(--primary-color);
}


.product-description {
    margin-bottom: 10px;
    text-align: left !important;
}

.product-description,
.variation-description {
    white-space: pre-wrap;
    text-align: center;
    font-size: 18px;
}

.product-img-container img {
    max-width: 400px;
    max-height: 400px;
    margin: auto;
    display: block;
    object-fit: cover;
    border-radius: 5px;
}

.variation-name {
    font-size: 20px;
    font-weight: 600;
    color: #3D553D;
    margin: 15px;
    margin-left: 0;
    margin-right: 10px;
}

.quantity-selector {
    min-width: 55%;
    margin: auto;
    max-width: 850px;
    width: 100%;
}

.packaging-annoncement {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}

.quantity-selector-description {
    /* font-style: oblique 40deg; */
    text-align: right;
    margin-top: 0;
    margin-bottom: 15px;
    text-align: center;
}

.global-qty-price-section {
    align-content: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 10px;
    flex-wrap: wrap;
}

.product-description,
.variation-description {
    text-align: center;
    font-size: 18px;
    white-space: break-spaces;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.select-qty-price {
    display: flex;
    justify-content: space-between;
}

.img-and-option-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
    padding: 20px;
}

.img-and-option-container > div {
    max-width: 500px;
}

.var-options-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    align-self: stretch;
}

.product-options-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}

.product-options-container>div {
    min-width: 150px;
}

.option-description {
    /* text-align: center; */
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 14px;
    font-style: oblique 10deg;
}

.scroll-button:hover {
    background-color: white;
    border: 1px solid var(--primary-color) !important;
}

.scroll-button {
    border: 1px solid var(--primary-color) !important;
    background-color: white;
    border-radius: 50%;
    font-weight: 900;
    max-width: 35px;
    height: 35px;
    margin: 5px;
    width: 10%;
    display: flex;
    color: var(--primary-color);
    align-items: center;
    justify-content: center;
}

.select-qty-price-container {
    max-width: 650px;
    width: 80%;
    display: flex;
    overflow: auto;
    scroll-behavior: smooth;
    padding-bottom: 7px;
    /* gap: 12px; */
}

.qty-price-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    gap: 8px;
}

.qty-button,
.cross-qty-button {
    height: 45px;
    width: 80px;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    background-color: var(--fith-color);
    font-weight: 400;
    border-radius: 3px;
    color: var(--third-color);
    position: relative;
}

.qty-price-section:not(:last-child) .qty-button:after {
    content: '';
    position: absolute;
    border-right: 1px solid var(--primary-color);
    height: 60%;
    top: 20%;
    right: 0;
}

.qty-price-section:first-child .qty-button {
    border-radius: 8px 0px 0px 8px !important;
}

.qty-price-section:last-child .qty-button {
    border-radius: 0px 8px 8px 0px !important;
}

.current-unity-price-container {
    margin-top: 7px;
    padding-top: 5px;
    max-width: 200px;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
}

.input-number-btn {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 4px;
    font-size: 24px;
    font-weight: 900;
    border-radius: 5px;
    background-color: #3d553d;
    color: white;
}

.product-qty {
    width: 70px !important;
    height: 30px !important;
    text-align: center !important;
    font-weight: 600;
    color: #3d553d;
    border-radius: 8px !important;
    margin: 0px;
    padding-top: 4px;
    border: 1px solid rgba(0, 0, 0, 0.7) !important;
}

.product-qty::-webkit-outer-spin-button,
.product-qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.product-qty[type=number] {
    -moz-appearance: textfield;
}

.quantity-price-indicator {
    text-align: center;
    min-width: 280px;
    border: 1px solid #3D553D;
    border-radius: 8px;
    padding: 20px;
}

.add-to-cart-img {
    display: block;
    margin: auto;
    width: 40px;
}

.option-product {
    font-weight: 600;
    font-size: 16px;
    color: #3D553D;
    background-color: #F5F5F5;
    border: none;
    padding: 8px;
}

.label-option {
    color: #3D553D;
}

@keyframes zoom {
    from {
        transform: scale(1);
    }

    50% {
        transform: scale(1.03);
    }

    to {
        transform: scale(1);
    }
}