.archive-nav-section {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-bottom: 1px solid var(--fith-color); */
    column-gap: 32px;
    flex-wrap: wrap;
}

.archive-navigation-button {
    padding: 5px 1px;
    color: var(--third-color);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-bottom: 3px solid rgba(255, 255, 255, 0);

}

.archive-navigation-button.active {
    /* padding: 5px 20px; */
    color: var(--primary-color);
    border-bottom: 3px solid var(--primary-color);
}