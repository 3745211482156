.packaging-type {
    font-size: 18px;
    font-weight: 500;
}

.packaging-description {
    margin: 5px 0 25px 0;
    font-size: 18px;
    white-space: pre-wrap;
}

.packaging-quantity-price-section {
    /* border-radius: 5px; */
    padding: 20px;
    margin-bottom: 5px;
}


/* GREEN VERSION */
.green-bg {
    background-color: var(--fith-color);
}

.white-bg {
    background-color: white;
}