.add-gabary-button {
    position: absolute;
    top: 48%;
    left: 0;
    right: 0;
    transition: all 0.2s;
    width: 100px;
    margin: 20px auto;
    padding: 12px;
    border-radius: 8px;
    color: white;
    background-color: #263a2e;
    text-align: center;
}

.gabarit-picture {
    /* max-width: 300px; */
    margin: 10px auto 20px auto;
    min-width: 250px;
    min-height: 100px;
    position: relative;
}

.gabarit-picture input {
    display: none;
}