.discount-select-section {
    display: flex;
    border-radius: 5px;
    border: 1px solid black;
    overflow: hidden;
    align-items: stretch;
    height: 40px;
    width: 100px;
}

.discount-select-section input:focus,
.discount-select-section select:focus,
.discount-select-section input:focus-visible,
.discount-select-section select:focus-visible,
.discount-select-section input,
.discount-select-section select {
    border: none !important;
    align-self: stretch;
    height: calc(100% + 4px);
    position: relative;
    top: -2px;
    right: -2px;
}

.discount-select-section input {
    text-align: right;
    border-radius: 0px !important;
    border-right: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.send-item-component table tr:nth-child(odd):hover,
.send-item-component table tr:nth-child(odd):hover+tr {
    background-color: #f5f5f6;
}

.tr+.send-item-component table tr:nth-child(even):hover,
.send-item-component table tr:nth-child(even):hover {
    background-color: #f5f5f6;
}

.total-prices-section {
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    padding: 10px 7px 7px 7px;
    font-size: 16px;
}

.total-prices-section>div:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 7px;
}